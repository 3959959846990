import React, { Component } from 'react'
import { graphql } from 'gatsby'
import propTypes from 'prop-types'
import Footer from '../components/footer'
import NavigationInterior from '../components/navigation-interior'

class PressPost extends Component {
    render() {
        const pressBody = {__html: this.props.data.contentfulPress.childContentfulPressPressBodyTextNode.childMarkdownRemark.html };
        const { title, imageLink, sourceUrl } = this.props.data.contentfulPress;

        return (
                <div key="one" className="single-post generic-container blog-post-container">
                    <div key="two"  className="single-post-inner">
                        <div key="three"  className="post-body-text">
                            <div key="four" className="post-body-text-internal">
                                <h3 key="post-title" className="post-title">
                                    {title}
                                </h3>
                            </div>
                        </div>
                        <div key="centered-img">
                            <a href={sourceUrl} target="_blank" rel="noopener noreferrer">
                                <img src={imageLink.fluid.src} alt="post-img"/>
                            </a>
                        </div>
                        <div key="five" className="post-body-text">
                            <div key="post_body_one" className="post-body-text-internal" dangerouslySetInnerHTML={pressBody}/>
                            <hr className="post-body-divider"></hr>
                        </div>
                    </div>
                    <NavigationInterior></NavigationInterior>
                    <Footer></Footer>
                </div>
        )
    }
}

PressPost.propTypes = {
    data: propTypes.object.isRequired
}

export default PressPost

export const pageQuery = graphql`
    query pressPostQuery($slug: String!){
        contentfulPress(slug: {eq: $slug}) {
            title
            childContentfulPressPressBodyTextNode {
              childMarkdownRemark {
                html
              }
            }
            sourceUrl
            imageLink {
                fluid (quality: 100) {
                    src
                }
            } 
        }
    }
`